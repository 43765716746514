import { UIBaseCosmicObject } from './commons';
import { CTAData } from './cta';

export type AccordionItemData = {
  title: string;
  description: string;
};

export enum AccordionLayoutKeys {
  DEFAULT = 'Default',
  WITH_DESCRIPTION = 'WithDescription',
}

export type AccordionCosmicMetadata = {
  title?: string;
  items: UIBaseCosmicObject<AccordionItemData>[];
  layout?: {
    key: AccordionLayoutKeys;
    value: string;
  };
  description_text?: string;
  cta?: CTAData;
  layout_options?: Record<string, boolean | string>;
  size?: string;
};
export type AccordionCosmicData = UIBaseCosmicObject<AccordionCosmicMetadata>;
